import 'Lessons/angularModule';
import angularDirectiveToReact from 'Angular2reactHelper';

// These are defined in a separate file so they can be mocked out in storybook (see __storybookMocks__/studentDashboardNgDirectives.ts)
export const TopMessageV1 = angularDirectiveToReact('FrontRoyal.Lessons', 'topMessage', {});
export const BookmarkedCompletedCoursesv1 = angularDirectiveToReact(
    'FrontRoyal.Lessons',
    'bookmarkedCompletedCourses',
    {},
);
export const LearningBoxV1 = angularDirectiveToReact('FrontRoyal.Lessons', 'studentDashboardLearningBox', {});
export const SidebarV1 = angularDirectiveToReact('FrontRoyal.Lessons', 'studentDashboardSidebar', {});
export const FeaturedEventsV1 = angularDirectiveToReact('FrontRoyal.Lessons', 'featuredEventsBox', {
    timezone: '<',
    onEventsLoaded: '<',
    studentNetworkActivated: '<',
});
