import { Textarea } from '@heroui/react';
import { type AnyObject } from '@Types';
import Button from 'Button';
import { InlineLink } from 'InlineLink';
import clsx from 'clsx';
import { useCurrentUser } from 'FrontRoyalAngular';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { FormProvider, useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { type PlayerViewModel } from 'Lessons';
import { useAppHeaderViewModel } from 'NavigationHelpers';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'Text';
import { BROWSER_AND_BUILD_INFO } from 'TinyEventLogger';
import { type CurrentUser } from 'Users';
import * as Yup from 'yup';

type ContactUsFormValues = {
    message: string;
    email: string;
    notes: string;
};

const formValidationSchema = Yup.object()
    .shape({
        message: Yup.string().required().min(1),
        email: Yup.string().email().required(),
        notes: Yup.string().required().min(1),
    })
    .required();

const getNotes = (user: CurrentUser | null, playerViewModel?: PlayerViewModel) => {
    if (!user) return '';
    let notes = {
        ...BROWSER_AND_BUILD_INFO,
        url: window.location.href,
        user_groups: user.groups.map(group => group.name).join(','),
        user_institutions: user.institutions.map(institution => institution.name).join(','),
        full_name: user.name,
        included_in_support_test: true,
    } as AnyObject;

    if (playerViewModel) {
        notes = {
            ...notes,
            lesson_title: playerViewModel.lesson.title,
            course_title: playerViewModel.stream.title,
            frame_index: playerViewModel.activeFrameIndex + 1,
            lesson_editor: `${window.ENDPOINT_ROOT + playerViewModel.lesson.editorUrl}?frame=${
                playerViewModel.activeFrameId
            }`,
            course_editor: window.ENDPOINT_ROOT + playerViewModel.stream.editorUrl,
            frame_complete: playerViewModel.activeFrameComplete,
            current_challenge_index: playerViewModel.currentChallengeIndexInModel,
            current_challenge_id: playerViewModel.currentChallenge?.id || null,
        };
    }

    return Object.entries(notes)
        .reduce<string[]>((prev, [key, val]) => [...prev, `${key}: ${val}`], [])
        .join('\n<br>\n');
};

const submitFeedback = (vals: ContactUsFormValues) =>
    fetch(`${window.ENDPOINT_ROOT}/feedback/new`, {
        method: 'POST',
        body: new URLSearchParams(vals),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

export const ContactUs = () => {
    const currentUser = useCurrentUser();
    const AppHeaderViewModel = useAppHeaderViewModel();
    const EventLogger = useEventLogger();
    const { t } = useTranslation('back_royal', { keyPrefix: 'support.support' });
    const lesson = useMemo(() => !!AppHeaderViewModel?.playerViewModel?.lesson, [AppHeaderViewModel?.playerViewModel]);
    const formFunctions = useForm<ContactUsFormValues>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: {
            message: '',
            email: currentUser?.email || '',
            notes: getNotes(currentUser, lesson ? AppHeaderViewModel?.playerViewModel : undefined),
        },
        mode: 'all',
    });

    const [submitted, setSubmitted] = useState(false);

    const { handleSubmit, register, watch } = formFunctions;

    const message = watch('message');

    return (
        <div
            className={clsx([
                'm-7.5 h-[440px]',
                { 'max-h-[calc(100vh-230px) mt-0': !lesson },
                { 'max-h-[calc(100vh-220px)': lesson },
            ])}
        >
            {submitted ? (
                <div className="flex h-full w-full">
                    <Text as="h2" className="m-auto" color="slate-grey">
                        {t('messageReceived')}
                    </Text>
                </div>
            ) : (
                <FormProvider {...formFunctions}>
                    <form
                        className="flex h-full flex-col"
                        onSubmit={handleSubmit(vals => {
                            submitFeedback(vals).then(() => {
                                EventLogger.log('message_submitted_to_help_scout', {
                                    params: {
                                        ...vals,
                                        message: vals.message.slice(0, 50),
                                    },
                                });
                                setSubmitted(true);
                            });
                        })}
                    >
                        <Textarea
                            placeholder={t('placeholder')!}
                            {...register('message')}
                            variant="bordered"
                            isMultiline
                            minRows={0}
                            maxRows={0}
                            classNames={{
                                base: 'flex-grow',
                                inputWrapper: 'h-full flex-grow border-1 p-3.5',
                                input: 'h-full flex-grow text-base leading-[1.4em] text-black placeholder:text-slate-grey',
                            }}
                        />
                        <Button
                            type="submit"
                            color="black"
                            fullWidth
                            className="mt-7.5 h-[50px] pt-1 text-md font-semibold uppercase disabled:bg-slate-grey-400"
                            isDisabled={!message}
                        >
                            {t('submit')}
                        </Button>
                        <Text as="p" color="slate-grey" className="mt-3.5">
                            {t('supportHours', {
                                FAQ: (
                                    <InlineLink href="/help" target="_blank" key="faq">
                                        FAQ
                                    </InlineLink>
                                ),
                            })}
                        </Text>
                    </form>
                </FormProvider>
            )}
        </div>
    );
};
