window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.student_network = window.locales.am.student_network || {};window.locales.am.student_network.student_network_event = window.locales.am.student_network.student_network_event || {};window.locales.am.student_network.student_network_event = {...window.locales.am.student_network.student_network_event, ...{
    "meetup": "Meetup",           
    "book_club": "የመጽሃፍ ክለብ",           
    "company_visit": "የንግድ ጉብኝቶች",           
    "special_event": "ልዩ ክስተት",           
    "conference": "ኮንፈረንስ",           
    "career_fair": "የስራ ትርዒት",           
    "admissions_event": "የምዝገባ ክስተቶች",           
    "online_event": "ምናባዊ ስብሰባ",           
    "virtual_meetup": "ምናባዊ አካባቢ ላይ የተመሰረተ ስብሰባ",           
    "documentary_club": "የዶክመንተሪ ክለብ",           
    "podcast_club": "የፖድካስት ክለብ",           
    "ted_talk_club": "TEDTalk ክለብ",
    "student_organization_events": "የተማሪ ድርጅት ዝግጅቶች",
    "ask_me_anything": "የፈለግከውን ጠይቀኝ",
    "office_hours": "የቢሮ ሰዓቶች",
    "academic_webinar": "አካዳሚክ Webinar",
    "in_person": "በአካል",
    "online": "በመስመር ላይ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.student_network = window.locales.ar.student_network || {};window.locales.ar.student_network.student_network_event = window.locales.ar.student_network.student_network_event || {};window.locales.ar.student_network.student_network_event = {...window.locales.ar.student_network.student_network_event, ...{
    "meetup": "نلتقي",
    "book_club": "نادي الكتاب",
    "company_visit": "زيارات العمل",
    "special_event": "حدث مميز",
    "conference": "مؤتمر",
    "career_fair": "معرض الوظائف",
    "admissions_event": "حدث القبول",
    "online_event": "لقاء افتراضي (ودية ، لا يوجد موقع)",
    "virtual_meetup": "اللقاء الافتراضي القائم على الموقع",
    "documentary_club": "نادي الأفلام الوثائقية",
    "podcast_club": "نادي البودكاست",
    "ted_talk_club": "نادي TEDTalk",
    "student_organization_events": "أحداث منظمة الطلاب",
    "ask_me_anything": "اسألني اي شئ",
    "office_hours": "ساعات العمل",
    "academic_webinar": "ندوة أكاديمية عبر الإنترنت",
    "in_person": "شخصيا",
    "online": "متصل"
}
};window.locales.en = window.locales.en || {};window.locales.en.student_network = window.locales.en.student_network || {};window.locales.en.student_network.student_network_event = window.locales.en.student_network.student_network_event || {};window.locales.en.student_network.student_network_event = {...window.locales.en.student_network.student_network_event, ...{
    "meetup": "Meetup",
    "book_club": "Book Club",
    "company_visit": "Business Visits",
    "special_event": "Special Event",
    "conference": "Conference",
    "career_fair": "Career Fair",
    "admissions_event": "Admissions Event",
    "online_event": "Virtual Meetup",
    "virtual_meetup": "Virtual Location-Based Meetup",
    "documentary_club": "Documentary Club",
    "podcast_club": "Podcast Club",
    "ted_talk_club": "TEDTalk Club",
    "student_organization_events": "Student Organization Events",
    "ask_me_anything": "Ask Me Anything",
    "office_hours": "Office Hours",
    "academic_webinar": "Academic Webinar",
    "in_person": "In-Person",
    "online": "Online"
}
};window.locales.es = window.locales.es || {};window.locales.es.student_network = window.locales.es.student_network || {};window.locales.es.student_network.student_network_event = window.locales.es.student_network.student_network_event || {};window.locales.es.student_network.student_network_event = {...window.locales.es.student_network.student_network_event, ...{
    "meetup": "Reunirse",
    "book_club": "Club del libro",
    "company_visit": "Visitas de Negocios",
    "special_event": "Evento especial",
    "conference": "Conferencia",
    "career_fair": "Feria de carreras",
    "admissions_event": "Evento de Admisiones",
    "online_event": "Reunión virtual",
    "virtual_meetup": "Reunión virtual basada en la ubicación",
    "documentary_club": "Club de documentales",
    "podcast_club": "Club de podcasts",
    "ted_talk_club": "Club de charlas TED",
    "student_organization_events": "Eventos de organizaciones estudiantiles",
    "ask_me_anything": "Pregúntame lo que sea",
    "office_hours": "Horas de oficina",
    "academic_webinar": "Seminario web académico",
    "in_person": "En persona",
    "online": "En línea"
}
};window.locales.it = window.locales.it || {};window.locales.it.student_network = window.locales.it.student_network || {};window.locales.it.student_network.student_network_event = window.locales.it.student_network.student_network_event || {};window.locales.it.student_network.student_network_event = {...window.locales.it.student_network.student_network_event, ...{
    "meetup": "Incontrarsi",
    "book_club": "Club del libro",
    "company_visit": "Visite di lavoro",
    "special_event": "Evento speciale",
    "conference": "Conferenza",
    "career_fair": "Fiera della carriera",
    "admissions_event": "Evento di ammissione",
    "online_event": "Meetup virtuale",
    "virtual_meetup": "Meetup basato sulla posizione virtuale",
    "documentary_club": "Documentary Club",
    "podcast_club": "Podcast Club",
    "ted_talk_club": "TEDTalk Club",
    "student_organization_events": "Eventi dell'organizzazione studentesca",
    "ask_me_anything": "Chiedimi qualunque cosa",
    "office_hours": "Orario di ufficio",
    "academic_webinar": "Webinar accademico",
    "in_person": "Di persona",
    "online": "In linea"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.student_network = window.locales.zh.student_network || {};window.locales.zh.student_network.student_network_event = window.locales.zh.student_network.student_network_event || {};window.locales.zh.student_network.student_network_event = {...window.locales.zh.student_network.student_network_event, ...{
    "meetup": "聚会",           
    "book_club": "读书俱乐部",           
    "company_visit": "商务考察",
    "special_event": "特殊活动",           
    "conference": "会议",           
    "career_fair": "人才招聘会",           
    "admissions_event": "招生活动",           
    "online_event": "虚拟聚会",
    "virtual_meetup": "基于虚拟位置的聚会",
    "documentary_club": "节目俱乐部",           
    "podcast_club": "播客俱乐部",           
    "ted_talk_club": "TED 谈话俱乐部",
    "student_organization_events": "学生组织活动",
    "ask_me_anything": "问我什么",
    "office_hours": "工作时间",
    "academic_webinar": "学术网络研讨会",
    "in_person": "亲自",
    "online": "在线的"
}
};