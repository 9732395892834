import { Route, useParams, useNavigate, Outlet } from 'react-router-dom';
import { useSuspenseFeatureGate } from 'FrontRoyalStatsig';
import { useEffect } from 'react';
import { ProtectedRoute } from 'ProtectedRoute';
import { ROUTES } from './constants';
import { useStudentDashboardVersion } from './useStudentDashboardVersion';
import { StudentDashboard, NEXT_UP_AND_CURRENTLY_STUDYING_GATE } from './StudentDashboard';

export function getStudentDashboardRoutes() {
    return (
        <Route
            path={ROUTES.STUDENT_DASHBOARD.relativePath}
            element={
                <ProtectedRoute>
                    <StudentDashboardV2 />
                </ProtectedRoute>
            }
        >
            <Route index element={<EnsureValidStudentDashboardTab />} />
            <Route path=":studyTab" element={<EnsureValidStudentDashboardTab />} />
        </Route>
    );
}

function StudentDashboardV2() {
    const studentDashboardVersion = useStudentDashboardVersion();

    return studentDashboardVersion === 2 ? <Outlet /> : null;
}

function EnsureValidStudentDashboardTab() {
    const enableTabs = useSuspenseFeatureGate(NEXT_UP_AND_CURRENTLY_STUDYING_GATE);
    const { studyTab } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!enableTabs && studyTab) {
            navigate('/dashboard', { replace: true });
            return;
        }

        if (enableTabs) {
            const validTabs = ['currently-studying', 'curriculum'];
            const defaultPath = '/dashboard/currently-studying';

            if (!studyTab || !validTabs.includes(studyTab)) {
                navigate(defaultPath, { replace: true });
            }
        }
    }, [enableTabs, studyTab, navigate]);

    return <StudentDashboard />;
}
