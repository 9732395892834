import moment from 'moment';
import { EVENT_TYPE_CONFIGS_MAP } from './StudentNetworkEventTypeConfigs';
import {
    type BaseStudentNetworkEvent,
    type EventType,
    type CamelCasedStudentNetworkEvent,
} from './StudentNetworkEvent.types';

export function eventTypeIcon(eventType?: EventType) {
    return eventType && EVENT_TYPE_CONFIGS_MAP[eventType].icon;
}

export function icon(studentNetworkEvent: BaseStudentNetworkEvent) {
    return studentNetworkEvent.event_type && eventTypeIcon(studentNetworkEvent.event_type);
}

export function eventTypeIsMappable(eventType?: EventType) {
    return eventType ? EVENT_TYPE_CONFIGS_MAP[eventType].mappable : false;
}

export function mappable(studentNetworkEvent: Pick<BaseStudentNetworkEvent, 'event_type'>) {
    return studentNetworkEvent.event_type && eventTypeIsMappable(studentNetworkEvent.event_type);
}

export function eventTypeHidesLocationDetails(eventType?: EventType) {
    return eventType ? EVENT_TYPE_CONFIGS_MAP[eventType].hidesLocationDetails : false;
}

export function hidesLocationDetails(studentNetworkEvent: Pick<BaseStudentNetworkEvent, 'event_type'>) {
    return studentNetworkEvent.event_type && eventTypeHidesLocationDetails(studentNetworkEvent.event_type);
}

/* For Dashboard 2.0 EventList */
export function getFormattedDateAndTimeForEvent(event: CamelCasedStudentNetworkEvent, timezone: string | null) {
    const startTimeMoment = (() => {
        if (!event.startTime) return null;
        if (timezone) return moment.tz(event.startTime * 1000, timezone);
        return moment(event.startTime * 1000);
    })();

    const formattedDate = startTimeMoment ? startTimeMoment.format('ddd, MMM D, YYYY') : null;

    const formattedTime = startTimeMoment ? startTimeMoment.format('h:mm A z') : null;

    return { formattedDate, formattedTime };
}

export function eventTypeIsOnline(eventType?: EventType) {
    return eventType ? !!EVENT_TYPE_CONFIGS_MAP[eventType]?.online : false;
}
