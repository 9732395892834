import { targetBrandConfig } from 'AppBranding';
import { useCurrentUser, useOfflineModeManager } from 'FrontRoyalAngular';
import { type FC, useEffect, useMemo, useState } from 'react';
import { getIsNotJoiningProgramOrHasBeenExpelled } from 'Users';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing } from '@fortawesome/pro-regular-svg-icons/faLifeRing';
import { Text } from 'Text';
import { twMerge } from 'tailwind-merge';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { SupportModal } from './SupportModal';

const conditionalBackgroundColorClass =
    '[body.bg-turquoise_&]:bg-white [body.bg-blue_&]:bg-white [body.bg-purple_&]:bg-white';

const conditionalFontColorClass =
    '[body.bg-turquoise_&]:text-turquoise-dark [body.bg-blue_&]:text-blue-dark [body.bg-purple_&]:text-purple-dark';

export const SupportSidebar: FC<{ aiTutor?: boolean; className?: string }> = ({ aiTutor, className }) => {
    const user = useCurrentUser();
    const OfflineModeManager = useOfflineModeManager();
    const { t } = useTranslation('back_royal', { keyPrefix: 'support.support' });
    const EventLogger = useEventLogger();

    const { supportsFeedbackSidebar } = targetBrandConfig(user);

    const isNotJoiningOrExpelled = getIsNotJoiningProgramOrHasBeenExpelled(user);

    const [showModal, setShowModal] = useState(false);

    const showSidebar = useMemo(
        () => !!user && !!supportsFeedbackSidebar && !isNotJoiningOrExpelled && !OfflineModeManager.inOfflineMode,
        [OfflineModeManager.inOfflineMode, isNotJoiningOrExpelled, supportsFeedbackSidebar, user],
    );

    useEffect(() => {
        if (showModal && !showSidebar) {
            setShowModal(false);
        }
    }, [showModal, showSidebar]);

    if (!showSidebar) return null;

    return (
        <button
            type="button"
            onClick={() => {
                setShowModal(true);
                EventLogger.log('support_sidebar_opened', {});
            }}
            // We need to force the dir and use the ltr/rtl variants to target the html dir below
            // since this can be rendered in a parent that has a hardcoded dir attribute
            dir="ltr"
            className={twMerge(
                'fixed left-0 top-[450px] z-[2] outline-none',
                'focus:outline-none notch:top-[calc(env(safe-area-inset-top)_+_450px)]',
                'animate-slide-in-left rtl:left-auto rtl:right-0 rtl:animate-slide-in-right',
                className,
            )}
        >
            <Text
                as="p"
                color="white"
                className={twMerge(
                    'inline-flex items-center bg-primary px-2.5 pb-0.5 pt-0.5 font-medium hover:pt-2',
                    'linear origin-top-left -rotate-90 transition-[padding] duration-150',
                    'flex-row rtl:origin-top-right rtl:translate-y-5 rtl:rotate-90 rtl:flex-row-reverse',
                    conditionalBackgroundColorClass,
                    conditionalFontColorClass,
                )}
            >
                <FontAwesomeIcon
                    icon={faLifeRing}
                    size="sm"
                    className={twMerge('mr-1 text-white rtl:ml-1 rtl:mr-0', conditionalFontColorClass)}
                />
                {t('support')}
            </Text>

            {showModal && (
                <SupportModal
                    aiTutor={!!aiTutor}
                    onClose={() => {
                        setShowModal(false);
                        EventLogger.log('support_modal_closed', {});
                    }}
                />
            )}
        </button>
    );
};
