import { Capsule } from 'Capsule';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { useCurrentUser } from 'FrontRoyalAngular';
import { useSyncConfig } from 'FrontRoyalConfig';
import { type CurrentUser } from 'Users';
import { Text } from 'Text';
import { EventImage } from './EventImage';
import { type CamelCasedStudentNetworkEvent } from './StudentNetworkEvent.types';
import { eventTypeIsOnline, getFormattedDateAndTimeForEvent } from './helpers';

type Props = {
    event: CamelCasedStudentNetworkEvent;
    onClick?: () => void;
    isMultipleEvents?: boolean;
};

type StudentNetworkEventPresentationProps = {
    event: CamelCasedStudentNetworkEvent;
    onClick?: () => void;
    chinaRegionMode: boolean;
    currentUser: CurrentUser;
    className?: string;
    isMultipleEvents?: boolean;
};

export function StudentNetworkEvent({ event, onClick, isMultipleEvents }: Props) {
    const config = useSyncConfig();
    const chinaRegionMode = config.chinaRegionMode();
    const currentUser = useCurrentUser();

    if (!currentUser) return null;

    return (
        <StudentNetworkEventPresentation
            event={event}
            onClick={onClick}
            chinaRegionMode={chinaRegionMode}
            currentUser={currentUser}
            isMultipleEvents={isMultipleEvents}
        />
    );
}

export function StudentNetworkEventPresentation({
    event,
    onClick,
    chinaRegionMode,
    currentUser,
    isMultipleEvents,
}: StudentNetworkEventPresentationProps) {
    const { t } = useTranslation('back_royal');

    const isOnline = eventTypeIsOnline(event.eventType);

    const locationType = isOnline
        ? t('student_network.student_network_event.online')
        : t('student_network.student_network_event.in_person');

    const timezone = chinaRegionMode ? 'Asia/Shanghai' : currentUser?.timezone ?? event.timezone;

    const { formattedDate, formattedTime } = getFormattedDateAndTimeForEvent(event, timezone);

    return (
        <button
            type="button"
            className="m-0 flex w-full items-start justify-between gap-5 border-none bg-none p-0 outline-none @container"
            onClick={onClick}
        >
            <div className="event-info pt-1">
                <Text color="black" weight="semibold" className="mb-2 text-start text-base leading-5 @[535px]:text-md">
                    {event.title}
                </Text>
                {!event.anonymized && event.locationName && (
                    <div className="mb-2.5 flex items-center justify-start">
                        <Text variant="small" weight="medium" color="slate-grey">
                            {event.locationName}
                        </Text>
                    </div>
                )}
                {formattedDate && (
                    <div className="mb-2 flex items-start justify-start gap-1.5">
                        <Text variant="small" color="slate-grey" className="shrink-0">
                            <FontAwesomeIcon className="relative top-0.5" icon={faCalendar} />
                        </Text>
                        <Text
                            variant="small"
                            weight="medium"
                            color="slate-grey"
                            uppercase
                            className="flex flex-wrap items-start justify-start gap-1"
                        >
                            {isMultipleEvents && <span>{t('lessons.stream.featured_events.multiple_times')}</span>}
                            {!isMultipleEvents && (
                                <>
                                    <span>{formattedDate}</span>
                                    {formattedTime && (
                                        <>
                                            <span>•</span>
                                            <span>{formattedTime}</span>
                                        </>
                                    )}
                                </>
                            )}
                        </Text>
                    </div>
                )}
                <div className="flex flex-wrap items-center justify-start gap-2">
                    <Capsule size="sm">{t(`student_network.student_network_event.${event.eventType}`)}</Capsule>
                    <Capsule size="sm">{locationType}</Capsule>
                </div>
            </div>
            <div className="event-image hidden @[535px]:block">
                <EventImage src={event.image?.formats?.original?.url ?? ''} alt={event.title} />
            </div>
            <div className="event-image block @[535px]:hidden">
                <EventImage small src={event.image?.formats?.original?.url ?? ''} alt={event.title} />
            </div>
        </button>
    );
}
