import { type FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { InlineLink } from 'InlineLink';

const StudyBoxComponent: FC = () => {
    const { studyTab } = useParams();

    return (
        <div className="min-h-[840px] rounded border border-gray-300 bg-white p-2">
            <div>
                <InlineLink href="/dashboard/currently-studying">Currently Studying</InlineLink>
                {' | '}
                <InlineLink href="/dashboard/curriculum">Curriculum</InlineLink>
            </div>
            {studyTab}
        </div>
    );
};

export const StudyBox = memo(StudyBoxComponent) as typeof StudyBoxComponent;
