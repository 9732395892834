/* eslint-disable @typescript-eslint/no-use-before-define */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'Button';
import { memo, type FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

type Props = {
    direction?: 'left' | 'right';
    hide?: boolean;
    onPress: () => void;
};

export const ScrollArrowButton: FC<Props> = memo(({ direction = 'right', hide = false, onPress }) => (
    <ArrowButton onPress={onPress} direction={direction} hide={hide} />
));

const ArrowButton = ({
    onPress,
    direction,
    hide,
}: {
    onPress: () => void;
    direction: 'left' | 'right';
    hide: boolean;
}) => (
    <Button
        isIconOnly
        variant="solid"
        color="white"
        onPress={onPress}
        className={twMerge(
            'absolute z-10 hidden transition-opacity ease-in-out md:block',
            hide ? 'pointer-events-none opacity-0' : 'opacity-100',
            direction === 'left' ? 'left-1.5' : 'right-1.5',
            'top-[139px]',
        )}
        radius="full"
        size="sm"
    >
        <FontAwesomeIcon
            className="inline text-sm text-slate-grey"
            icon={direction === 'left' ? faChevronLeft : faChevronRight}
        />
    </Button>
);
