import { type StreamPlayerApi, Stream } from '@cloudflare/stream-react';
import { useCurrentUser } from 'FrontRoyalAngular';
import { fetchBrandConfig } from 'AppBranding';
import { useState, useEffect, type FC, useMemo, useRef, useCallback, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import isChromatic from 'chromatic';
import { Modal } from 'Modal';
import { type VideoComponentProps, type StudentSpotlightVideoProps } from './StudentSpotlight.types';
// import { Skeleton } from '@heroui/react';

const minimizedContainerClasses =
    'relative w-[137.5px] h-[244.44px] shrink-0 overflow-hidden rounded-8 shadow-smallish hover:cursor-pointer hover:shadow-small';
const minimizedStreamClass = 'transition-transform duration-300 ease-in-out';
const expandedStreamClass = 'max-w-[90vw] h-[600px] pt-0 aspect-[9/16]';
const scaleClass = 'scale-125';

export const VideoComponent: FC<VideoComponentProps> = memo(
    ({
        src,
        containerClass,
        streamClass = '',
        expanded = false,
        onClick,
        autoplay = false,
        showInstitutionLogo = false,
        playOnHover = false,
        scaleOnHover = false,
        videoRef,
        shouldLoad,
    }) => {
        const [isLoaded, setIsLoaded] = useState(false);
        const [streamClassName, setStreamClassName] = useState(streamClass);
        const currentUser = useCurrentUser();
        const streamRef = useRef<StreamPlayerApi | undefined>(undefined);
        /**
         * Grab brand config for logo
         */
        const userBrandConfig = useMemo(() => {
            if (!currentUser?.activeInstitution?.id || !showInstitutionLogo) return null;
            return fetchBrandConfig(currentUser?.activeInstitution.id);
        }, [currentUser?.activeInstitution?.id, showInstitutionLogo]);

        useEffect(() => {
            // Restart video upon expansion
            if (expanded && streamRef.current) streamRef.current.currentTime = 0;
        }, [expanded, containerClass, streamClass]);

        /**
         * Hover logic
         */
        const mouseEnterHandler = useCallback(() => {
            if (scaleOnHover) setStreamClassName(val => twMerge(val, scaleClass));
            if (!streamRef.current || autoplay || !playOnHover) return;

            streamRef.current.play();
        }, [autoplay, playOnHover, scaleOnHover]);

        const mouseLeaveHandler = useCallback(() => {
            if (scaleOnHover) setStreamClassName(val => val.replace(scaleClass, ''));
            if (!streamRef.current || autoplay || !playOnHover) return;

            streamRef.current.currentTime = 0;
            streamRef.current.pause();
        }, [autoplay, playOnHover, scaleOnHover]);

        return (
            <div
                ref={videoRef}
                className={containerClass}
                onClick={onClick}
                onKeyDown={e => e.key === 'Enter' && onClick?.()}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                role="button"
                tabIndex={0}
            >
                {/* TODO: Re-enable once we've merged in a solution for HeroUI's shimmer animation not working */}
                {/* {(!shouldLoad || !isLoaded) && <Skeleton className="h-full w-full" />} */}

                {(isLoaded || shouldLoad) && !isChromatic() && (
                    <Stream
                        autoplay={autoplay}
                        muted={!expanded}
                        loop
                        className={streamClassName}
                        src={src}
                        streamRef={streamRef}
                        controls={expanded}
                        onLoadedData={() => setIsLoaded(true)}
                    />
                )}

                {!expanded && isLoaded && (
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                )}

                {userBrandConfig?.acceptedApplicationLogo && isLoaded && (
                    <div className={twMerge('absolute right-3 top-2 max-w-[70px]', expanded ? 'hidden' : '')}>
                        <img src={userBrandConfig.acceptedApplicationLogo} alt={`${userBrandConfig.branding} logo`} />
                    </div>
                )}
            </div>
        );
    },
);

export const StudentSpotlightVideo: FC<StudentSpotlightVideoProps> = memo(
    ({
        src,
        autoplayPreview = false,
        playPreviewOnHover = false,
        scalePreviewOnHover = false,
        showInstitutionLogo = false,
        videoRef,
        shouldLoad,
    }) => {
        const [expanded, setExpanded] = useState(false);

        return (
            <>
                <VideoComponent
                    src={src}
                    containerClass={minimizedContainerClasses}
                    streamClass={minimizedStreamClass}
                    onClick={() => setExpanded(true)}
                    autoplay={autoplayPreview}
                    playOnHover={playPreviewOnHover}
                    scaleOnHover={scalePreviewOnHover}
                    showInstitutionLogo={showInstitutionLogo}
                    videoRef={videoRef}
                    shouldLoad={shouldLoad}
                />

                {expanded && (
                    <Modal
                        onClose={() => setExpanded(false)}
                        dismissOnOverlayClick
                        dismissOnEscape
                        hideCloseButton
                        size="unset"
                        duration={0.7}
                    >
                        <VideoComponent src={src} expanded autoplay shouldLoad streamClass={expandedStreamClass} />
                    </Modal>
                )}
            </>
        );
    },
);
