import { Text } from 'Text';
import { useTranslation } from 'react-i18next';
import { type RefObject, type FC, useRef, useCallback, createRef, memo, useMemo } from 'react';
import clsx from 'clsx';
import { useIntersectionObserver, useIntersectionObservers } from 'useIntersectionObserver';
import { useTargetBrandConfig } from 'FrontRoyalAngular/useTargetBrandConfig';
import { StudentSpotlightVideo } from './StudentSpotlightVideo';
import { ScrollArrowButton } from './ScrollArrowButton';
import { type StudentSpotlightCarouselProps } from './StudentSpotlight.types';

type StreamIndexRefs = {
    [index: string]: RefObject<HTMLDivElement>;
};

export const StudentSpotlightCarousel: FC<StudentSpotlightCarouselProps> = memo(
    // eslint-disable-next-line max-lines-per-function
    ({
        streamIds,
        autoplayFirstPreview = true,
        playPreviewOnHover = true,
        scalePreviewOnHover = true,
        showInstitutionLogo = false,
    }) => {
        const { t } = useTranslation('back_royal');
        const brandConfig = useTargetBrandConfig();
        const { brandNameShort } = brandConfig;

        const containerRef = useRef<HTMLDivElement>(null);
        const carouselRef = useRef<HTMLDivElement>(null);
        const isVerticallyCloseToVisible = useIntersectionObserver(containerRef, {
            // app-main-container is the scrollable part of the page
            root: document.querySelector('#app-main-container')!,
            rootMargin: '200px 0px 400px 0px',
        });

        const streamIndexRefs = useRef<StreamIndexRefs>(
            streamIds.reduce<StreamIndexRefs>((acc, _, index) => {
                acc[index] = createRef();
                return acc;
            }, {}),
        );

        const refsList = useMemo(() => (streamIds ? Object.values(streamIndexRefs.current) : []), [streamIds]);

        const visibleStreams = useIntersectionObservers(refsList, {
            rootMargin: '150px 0px 150px 0px',
            root: containerRef.current,
            threshold: 0.95,
        });

        const leftmostVisibleStreamIndex = useMemo(
            () => visibleStreams.findIndex(visible => visible),
            [visibleStreams],
        );
        const rightmostVisibleStreamIndex = useMemo(
            () => visibleStreams.findLastIndex(visible => visible),
            [visibleStreams],
        );

        const onPress = useCallback(
            (direction: 'left' | 'right') => () => {
                const carousel = carouselRef.current;
                const container = containerRef.current;
                if (!carousel || !container) return;

                const parentWidth = container.clientWidth;

                const targetIndex =
                    direction === 'left' ? leftmostVisibleStreamIndex - 1 : rightmostVisibleStreamIndex + 1;

                const nextStream = streamIndexRefs.current[targetIndex];
                if (!nextStream?.current) return;

                const itemLeft = nextStream.current.offsetLeft;
                const itemRight = itemLeft + nextStream.current.offsetWidth;

                const scroll = direction === 'left' ? itemLeft : itemRight - parentWidth;

                carousel.scrollTo({
                    left: scroll,
                    behavior: 'smooth',
                });
            },
            [leftmostVisibleStreamIndex, rightmostVisibleStreamIndex],
        );

        const getShouldLoad = useCallback(
            (i: number) =>
                // If the within the range of visible streams +/- 2
                // i.e if 3,4,5 are visible, then 1-7 are loaded
                // and the entire container is close to/or is visible
                leftmostVisibleStreamIndex - 2 <= i &&
                i <= rightmostVisibleStreamIndex + 2 &&
                isVerticallyCloseToVisible,
            [leftmostVisibleStreamIndex, rightmostVisibleStreamIndex, isVerticallyCloseToVisible],
        );

        return (
            <div className="relative -mx-2.5 overflow-hidden sm:-mx-7.5 md:mx-0" ref={containerRef}>
                <Text as="h2" uppercase className="mb-3.5 ml-[22px] sm:ml-[42px] md:ml-3">
                    {t('featured_students.featured_students.branded_student_spotlight', { brandNameShort })}
                </Text>
                <ScrollArrowButton direction="left" hide={visibleStreams[0]} onPress={onPress('left')} />
                <div
                    ref={carouselRef}
                    className={clsx(
                        'hide-scrollbar md:space-between flex flex-row justify-start',
                        'gap-x-7.5 overflow-x-auto overflow-y-visible p-15',
                        '-my-15 -ml-12.5 -mr-15 sm:-ml-7.5 sm:-mr-7.5 md:-mx-15',
                    )}
                >
                    {streamIds.map((streamId, i) => (
                        <StudentSpotlightVideo
                            key={streamId}
                            src={streamId}
                            autoplayPreview={autoplayFirstPreview && i === 0}
                            playPreviewOnHover={playPreviewOnHover}
                            scalePreviewOnHover={scalePreviewOnHover}
                            showInstitutionLogo={showInstitutionLogo}
                            videoRef={streamIndexRefs.current[i]}
                            shouldLoad={getShouldLoad(i)}
                        />
                    ))}
                </div>
                <ScrollArrowButton
                    direction="right"
                    hide={visibleStreams[visibleStreams.length - 1]}
                    onPress={onPress('right')}
                />
            </div>
        );
    },
);
