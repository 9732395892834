window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.support = window.locales.am.support || {};window.locales.am.support.support = window.locales.am.support.support || {};window.locales.am.support.support = {...window.locales.am.support.support, ...{
    "haveQuestions": "ጥያቄዎች አሉዎት?",
    "askAiTutor": "AI አስተማሪን ይጠይቁ",
    "contactUs": "ያግኙን",
    "submit": "አስገባ",
    "supportHours": "የድጋፍ ሰአታት ከ MF 9 AM እስከ 5 PM ET ናቸው። እንዲሁም የእኛን {FAQ} መመልከት ሊፈልጉ ይችላሉ።",
    "support": "ድጋፍ",
    "messageReceived": "መልእክት ደረሰ። አመሰግናለሁ!",
    "placeholder": "ሰላም፣ ለማለት ፈልጌ ነበር…"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.support = window.locales.ar.support || {};window.locales.ar.support.support = window.locales.ar.support.support || {};window.locales.ar.support.support = {...window.locales.ar.support.support, ...{
    "haveQuestions": "هل لديك أسئلة؟",
    "askAiTutor": "اسأل AI المعلم",
    "contactUs": "اتصل بنا",
    "submit": "يُقدِّم",
    "supportHours": "ساعات الدعم متاحة من الساعة 9 صباحًا حتى 5 مساءً بالتوقيت الشرقي. قد ترغب أيضًا في مراجعة {FAQ} الخاصة بنا.",
    "support": "يدعم",
    "messageReceived": "تم استلام الرسالة. شكرا لك!",
    "placeholder": "مرحبًا، أردت فقط أن أقول..."
}
};window.locales.en = window.locales.en || {};window.locales.en.support = window.locales.en.support || {};window.locales.en.support.support = window.locales.en.support.support || {};window.locales.en.support.support = {...window.locales.en.support.support, ...{
    "haveQuestions": "Have Questions?",
    "askAiTutor": "Ask AI Tutor",
    "contactUs": "Contact Us",
    "submit": "Submit",
    "support": "Support",
    "supportHours": "Support hours are M-F 9 AM to 5 PM ET. You may also wish to check our {FAQ}.",
    "messageReceived": "Message Received. Thanks!",
    "placeholder": "Hi, I just wanted to say…"
}
};window.locales.es = window.locales.es || {};window.locales.es.support = window.locales.es.support || {};window.locales.es.support.support = window.locales.es.support.support || {};window.locales.es.support.support = {...window.locales.es.support.support, ...{
    "haveQuestions": "¿Tiene preguntas?",
    "askAiTutor": "Pregúntele al tutor AI",
    "contactUs": "Contáctenos",
    "submit": "Entregar",
    "supportHours": "El horario de atención al cliente es de lunes a viernes de 9:00 a 17:00 (hora del Este de EE. UU.). También puede consultar nuestras {FAQ}.",
    "support": "Apoyo",
    "messageReceived": "Mensaje recibido. ¡Gracias!",
    "placeholder": "Hola, solo quería decir…"
}
};window.locales.it = window.locales.it || {};window.locales.it.support = window.locales.it.support || {};window.locales.it.support.support = window.locales.it.support.support || {};window.locales.it.support.support = {...window.locales.it.support.support, ...{
    "haveQuestions": "Hai domande?",
    "askAiTutor": "Chiedi al tutor AI",
    "contactUs": "Contattaci",
    "submit": "Invia",
    "supportHours": "Gli orari di supporto sono dal lunedì al venerdì dalle 9:00 alle 17:00 ET. Potresti anche voler controllare le nostre {FAQ}.",
    "support": "Supporto",
    "messageReceived": "Messaggio ricevuto. Grazie!",
    "placeholder": "Ciao, volevo solo dire..."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.support = window.locales.zh.support || {};window.locales.zh.support.support = window.locales.zh.support.support || {};window.locales.zh.support.support = {...window.locales.zh.support.support, ...{
    "haveQuestions": "有疑问吗？",
    "askAiTutor": "询问AI导师",
    "contactUs": "联系我们",
    "submit": "提交",
    "supportHours": "支持时间为每周一上午 9 点至下午 5 点（美国东部时间）。您也可以查看我们的{FAQ}。",
    "support": "支持",
    "messageReceived": "消息已收到。谢谢！",
    "placeholder": "嗨，我只是想说……"
}
};