import clsx from 'clsx';

export function EventImage({ src, alt, small }: { src: string; alt: string; small?: boolean }) {
    return (
        <div
            className={clsx('h-[90px] rounded-5 bg-cover bg-center', {
                'w-[90px]': small,
                'w-[160px]': !small,
            })}
            style={{ backgroundImage: `url(${src})` }}
        >
            <img src={src} alt={alt} className="sr-only" />
        </div>
    );
}
