import { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'Modal';
import { SupportModalBody } from './SupportModalBody';

export const SupportModal: FC<{ onClose: () => void; aiTutor: boolean }> = ({ onClose, aiTutor }) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'support.support' });

    const title = useMemo(() => t('haveQuestions'), [t]);

    return (
        <Modal title={title} size="2xl" onClose={onClose} dismissOnOverlayClick={false} dismissOnEscape={false}>
            <SupportModalBody aiTutor={aiTutor} />
        </Modal>
    );
};
