import { type Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Segment, SegmentedControl } from 'SegmentedControl';
import { useAppHeaderViewModel } from 'NavigationHelpers';
import { LessonPlayerBot } from 'TutorBotAiTutorChat';
import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { ContactUs } from './ContactUs';

const useLogInitialRender = () => {
    const EventLogger = useEventLogger();
    const { t } = useTranslation('back_royal', { keyPrefix: 'support.support' });

    const [shouldLogState, setShouldLogState] = useState({ lessonPlayerBot: true, contactUs: true });

    const logOnInitialRender = (key: Key) => {
        if (key === t('askAiTutor') && shouldLogState.lessonPlayerBot) {
            EventLogger.log('support_modal_swapped_to_player_bot_tab');
            setShouldLogState({ ...shouldLogState, lessonPlayerBot: false });
        }
        if (key === t('contactUs') && shouldLogState.contactUs) {
            EventLogger.log('support_modal_swapped_to_contact_us_tab', {});
            setShouldLogState({ ...shouldLogState, contactUs: false });
        }
    };

    return logOnInitialRender;
};

export const AiTutorAndContactUs = () => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'support.support' });
    const AppHeaderViewModel = useAppHeaderViewModel();
    const currentUser = useCurrentUserIguanaObject();
    const logOnInitialRender = useLogInitialRender();

    useEffect(() => {
        if (!AppHeaderViewModel.playerViewModel) return undefined;

        AppHeaderViewModel.playerViewModel.launchBot(true);

        return () => {
            AppHeaderViewModel.playerViewModel?.hideBot?.();
        };
    }, [AppHeaderViewModel.playerViewModel]);

    const segments: Segment[] = useMemo(() => {
        if (!currentUser || !AppHeaderViewModel.playerViewModel?.botContentInfo) return [];

        return [
            {
                title: t('askAiTutor'),
                content: (
                    <LessonPlayerBot
                        currentUser={currentUser}
                        contentInfo={AppHeaderViewModel.playerViewModel.botContentInfo}
                        uiContext={AppHeaderViewModel.playerViewModel.botUiContext}
                        initialMessage={AppHeaderViewModel.playerViewModel.initialBotMessage}
                        className="h-[500px] p-0 sm:p-0"
                        supportTab
                    />
                ),
            },
            { title: t('contactUs'), content: <ContactUs /> },
        ];
    }, [
        currentUser,
        AppHeaderViewModel.playerViewModel?.botContentInfo,
        AppHeaderViewModel.playerViewModel?.botUiContext,
        AppHeaderViewModel.playerViewModel?.initialBotMessage,
        t,
    ]);

    return <SegmentedControl segments={segments} classNames={{ base: 'mt-5' }} onSegmentChange={logOnInitialRender} />;
};
