import { type AnyObject } from '@Types';
import { type CurrentUserIguanaObject } from 'Users';

export function onFeaturedEventsLoaded(
    orderedEventTitles: string[],
    studentNetworkActivated: boolean,
    currentUser: CurrentUserIguanaObject,
) {
    if (studentNetworkActivated && !orderedEventTitles.length) {
        // If we get here, then that means the user likely deferred into a future cohort after having
        // their network activated. That means they might not have any events since their cohort could
        // be far in the future. In that scenario, we set this property and use it to determine if the
        // Featured Events box should be shown. It's intentional that this property gets wiped away with
        // every page refresh as we want to check if there are upcoming events every time the user opens
        // the app. Setting this property on the current user works as a temp session storage and should
        // not actually be on the currentUser type.
        (currentUser as AnyObject).showFeaturedEvents = false;
    }
}
