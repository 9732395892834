import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import { type CamelCasedStudentNetworkEvent } from 'StudentNetworkEvent';

type StudentNetworkEventsIndexResponse = {
    contents: {
        studentNetworkEvents: CamelCasedStudentNetworkEvent[];
    };
    meta: {
        totalCount: number;
    };
};

export const studentNetworkApi = createApi('studentNetworkApi', {
    ...getBackRoyalApiBaseConfig('studentNetworkApi'),
    endpoints: builder => ({
        getStudentNetworkEvents: builder.query<CamelCasedStudentNetworkEvent[], { featured: boolean }>({
            query: ({ featured }) => ({
                url: 'student_network_events.json',
                method: 'GET',
                params: {
                    filters: {
                        featured,
                        include_tbd: featured,
                    },
                    sort: 'start_time',
                },
            }),
            transformResponse: (response: StudentNetworkEventsIndexResponse) => response.contents.studentNetworkEvents,
        }),
    }),
});
