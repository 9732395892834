import { createOutsideModal } from 'Modal';
import { type auto } from 'angular';
import { SupportModalBody } from './SupportModalBody';

export const createOutsideSupportModal = (injector: auto.IInjectorService, title: string) =>
    createOutsideModal(injector, {
        title,
        children: <SupportModalBody />,
        size: '2xl',
        dismissOnOverlayClick: false,
        dismissOnEscape: false,
    });
