import { type FC, memo, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Container } from 'Container';
import { useSuspenseFeatureGate } from 'FrontRoyalStatsig';
import { FeaturedStudents } from 'FeaturedStudents';
import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { type CurrentUserIguanaObject, getCohort, getProgramInclusion } from 'Users';
import { type AnyObject } from '@Types';
import { EventList } from 'EventList';
import { navigationHelper } from 'NavigationHelpers';
import { ProgramTypeConfigs } from 'Program';
import { StudentSpotlightCarousel, type StudentSpotlightCarouselProps } from 'StudentSpotlight';
import { useStudentDashboardContent } from 'LearnerContentCache';
import { SupportSidebar } from 'Support/SupportSideBar';
import {
    TopMessageV1,
    BookmarkedCompletedCoursesv1,
    SidebarV1,
    FeaturedEventsV1,
    LearningBoxV1,
} from './studentDashboardNgDirectives';
import { StudyBox } from './StudyBox';
import { onFeaturedEventsLoaded } from './onFeaturedEventsLoaded';

export const NEXT_UP_AND_CURRENTLY_STUDYING_GATE = 'sd_next_up_and_currently_studying';

function Placeholder({ className, children }: { className?: string; children: React.ReactNode }) {
    // merge "rounded border border-gray-300 bg-white" into the className
    return <div className={twMerge('rounded border border-gray-300 bg-white p-2', className)}>{children}</div>;
}

function WelcomeMessageGate() {
    const welcomeMessageVersion = useSuspenseFeatureGate('sd_welcome_message_v2') ? 2 : 1;

    return welcomeMessageVersion === 1 ? (
        <TopMessageV1 />
    ) : (
        <Placeholder className="min-h-[94px]">Welcome Message</Placeholder>
    );
}

function BookmarkedCompletedCoursesGate() {
    const bookmarkedCoursesVersion = useSuspenseFeatureGate('sd_bookmarked_courses_v2') ? 2 : 1;

    return bookmarkedCoursesVersion === 1 ? (
        <BookmarkedCompletedCoursesv1 />
    ) : (
        <Placeholder className="min-h-[280px]">Bookmarked Courses</Placeholder>
    );
}

function FeaturedStudentsGate({
    studentNetworkActivated,
    studentSpotlightVersion,
    meetClassVersion,
    eventsVersion,
    studentSpotlightCarouselProps,
}: {
    studentNetworkActivated: boolean;
    studentSpotlightVersion: number;
    meetClassVersion: number;
    eventsVersion: number;
    studentSpotlightCarouselProps: StudentSpotlightCarouselProps;
}) {
    const { loadRoute } = navigationHelper();

    if (meetClassVersion === 2 && studentNetworkActivated) {
        return <Placeholder className="min-h-[280px]">Meet Your Class</Placeholder>;
    }
    if (studentSpotlightVersion === 2 && !studentNetworkActivated) {
        return <StudentSpotlightCarousel {...studentSpotlightCarouselProps} />;
    }

    return (
        <div className={twMerge(eventsVersion === 1 ? 'order-2' : '')}>
            <FeaturedStudents inStudentNetwork={studentNetworkActivated} loadRoute={loadRoute} />
        </div>
    );
}

function EventsGate({
    currentUser,
    studentNetworkActivated,
    showFeaturedEvents,
    setShowFeaturedEvents,
    eventsVersion,
}: {
    currentUser: CurrentUserIguanaObject;
    studentNetworkActivated: boolean;
    showFeaturedEvents: boolean;
    setShowFeaturedEvents: (showFeaturedEvents: boolean) => void;
    eventsVersion: number;
}) {
    return eventsVersion === 1 ? (
        showFeaturedEvents && (
            <div className="order-1">
                <FeaturedEventsV1
                    timezone={currentUser.timezone}
                    studentNetworkActivated={studentNetworkActivated}
                    onEventsLoaded={(orderedEventTitles: string[]) => {
                        onFeaturedEventsLoaded(orderedEventTitles, studentNetworkActivated, currentUser);

                        // `currentUser.showFeaturedEvents` can be explicitly set to false in `onFeaturedEventsLoaded`.
                        if ((currentUser as AnyObject).showFeaturedEvents === false) {
                            setShowFeaturedEvents(false);
                        }
                    }}
                />
            </div>
        )
    ) : (
        <EventList featured={!studentNetworkActivated} />
    );
}

function FeaturedStudentsAndEventsGate() {
    const studentSpotlightVersion = useSuspenseFeatureGate('sd_student_spotlight_and_events_v2') ? 2 : 1;
    const meetClassVersion = useSuspenseFeatureGate('sd_meet_class_and_events_v2') ? 2 : 1;

    const currentUser = useCurrentUserIguanaObject();
    const [showFeaturedEvents, setShowFeaturedEvents] = useState(false);
    const studentNetworkActivated = useMemo(
        () => !!getProgramInclusion(currentUser)?.studentNetworkActivated,
        [currentUser],
    );
    const cohort = useMemo(() => getCohort(currentUser), [currentUser]);
    const programTypeConfig = cohort && ProgramTypeConfigs[cohort.programType];

    const eventsVersion =
        (studentSpotlightVersion === 2 && !studentNetworkActivated) ||
        (meetClassVersion === 2 && studentNetworkActivated)
            ? 2
            : 1;

    useEffect(() => {
        if (currentUser) {
            setShowFeaturedEvents((currentUser as AnyObject).showFeaturedEvents !== false);
        } else {
            setShowFeaturedEvents(false);
        }
    }, [currentUser]);

    if (!currentUser || !currentUser.hasStudentNetworkAccess) {
        return null;
    }

    return (
        <div className={twMerge(eventsVersion === 1 ? 'grid grid-cols-2 gap-4' : 'flex flex-col gap-7.5')}>
            <FeaturedStudentsGate
                studentNetworkActivated={studentNetworkActivated}
                studentSpotlightVersion={studentSpotlightVersion}
                meetClassVersion={meetClassVersion}
                eventsVersion={eventsVersion}
                studentSpotlightCarouselProps={programTypeConfig?.studentSpotlightCarouselProps ?? { streamIds: [] }}
            />
            <EventsGate
                currentUser={currentUser}
                studentNetworkActivated={studentNetworkActivated}
                showFeaturedEvents={showFeaturedEvents}
                setShowFeaturedEvents={setShowFeaturedEvents}
                eventsVersion={eventsVersion}
            />
        </div>
    );
}

function SidebarGate() {
    const sidebarVersion = useSuspenseFeatureGate('sd_sidebar_v2') ? 2 : 1;

    return sidebarVersion === 1 ? (
        <SidebarV1 />
    ) : (
        <Placeholder className="w-full max-w-[350px] sm:hidden">Sidebar</Placeholder>
    );
}

function LearningBoxGate() {
    const learningBoxVersion = useSuspenseFeatureGate('sd_next_up_and_currently_studying_v2') ? 2 : 1;
    return learningBoxVersion === 1 ? <LearningBoxV1 /> : <StudyBox />;
}

// eslint-disable-next-line arrow-body-style
const StudentDashboardComponent: FC = () => {
    // const nextUpAndCurrentlyStudyingVersion = StatsigSidecar?.checkGate(NEXT_UP_AND_CURRENTLY_STUDYING_GATE) ? 2 : 1;
    // const sidebarVersion = StatsigSidecar?.checkGate('sd_sidebar_v2') ? 2 : 1;

    // // The app menu has to be updated before the new curriculum version can
    // // be live, because we want to remove the courses section of the app at
    // // the same time as we update the curriculum version here
    // let curriculumVersion: number;
    // if (StatsigSidecar?.checkGate('app_menu_tabs_v2')) {
    //     curriculumVersion = StatsigSidecar?.checkGate('sd_curriculum_v2') ? 2 : 1;
    // }

    // While we're not directly referencing the dashboard content, we want to call this suspendable hook before rendering
    // the student dashboard v2 component. This mimics what we were previously doing in the v1 of the student dashboard.
    // While the dashboard content is loading, the topmost suspense boundary will render a spinner and when the content loads,
    // we'll render this component. Once we have some code that's actually using `useStudentDashboardContent`,
    // we can remove this
    useStudentDashboardContent();

    return (
        <Container className="fade-in pb-[55px] sm:pb-0">
            <SupportSidebar className="hidden md:block" />
            <div className="student-dashboard dashboard-v2 mx-auto flex flex-col gap-3.5 sm:py-7">
                {/* First Row */}
                <div className="flex flex-col justify-between gap-3.5 md:flex-row md:gap-7.5">
                    {/* Main Content */}
                    <div className="main-column flex w-full shrink-0 flex-col gap-3.5 md:w-2/3 md:grow-0 md:gap-7.5">
                        <WelcomeMessageGate />
                        <LearningBoxGate />
                        <FeaturedStudentsAndEventsGate />
                    </div>

                    {/* Sidebar */}
                    <SidebarGate />
                </div>

                {/* Second Row */}
                <div className="bookmarked-courses-container w-full">
                    <BookmarkedCompletedCoursesGate />
                </div>
            </div>
        </Container>
    );
};

export const StudentDashboard = memo(StudentDashboardComponent) as typeof StudentDashboardComponent;
